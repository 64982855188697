<template>
   <div class="container">

      <div class="dateList">
         <div class="item" v-for="(item,index) in collectionList" :key="index" :class="{active:collectionActive==item}" @click="clickCollectionItem(item)">{{item}}</div>
      </div>
      <div class="content">
         <div class="tabbar">
            <div class="item" :class="{active:dataTypeActive=='all'}" @click='dataTypeActive="all"'>所有</div>
            <div class="item" :class="{active:dataTypeActive=='error'}" @click='dataTypeActive="error"'>错误</div>
            <div class="item" :class="{active:dataTypeActive=='info'}" @click='dataTypeActive="info"'>信息</div>
         </div>
         <div ref='logContent' class="logContent">
            <div class="item" v-for="(item,index) in dataList" :key="index" :class="item.type">
               <div style="flex-shrink:0">{{item.type.toUpperCase()}}</div>
               <!-- <div>{{item._id}}</div> -->
               <div style="flex-shrink:0">{{item.time}}</div>
               <div style="flex-shrink:2">{{item.content}}</div>
            </div>
         </div>
      </div>
      

   </div>
</template>

<script>
export default {
    data(){
      return {
         collectionList:[],
         collectionActive:"",
         dataTypeActive:"all",
         dataList:[],

         webSocket:null,           // websocket对象
         webSocketStatus:"未连接",  // websocket连接状态
         reconnectNumber:0,        // websocket重连次数
      }
   },
   mounted(){
      this.webSocketConnect();
   },
   methods:{
      clickCollectionItem(item){
         this.collectionActive = item;
         this.dataList = [];
         this.webSocket.send(JSON.stringify({
            operationType:'log',
            collectionName:this.collectionActive,
            dataType:this.dataTypeActive,
         }));
      },
      // .0 建立webSocket连接 -------------------------------------------------------------
      webSocketConnect(){

         let that = this;

         // 官方文档：https://developer.mozilla.org/zh-CN/docs/Web/API/WebSocket
         this.webSocketStatus = "正在连接……";
         if(this.$env === "production"){
            this.webSocket = new WebSocket("ws://118.24.103.166:49151");
         }else{
            this.webSocket = new WebSocket("ws://127.0.0.1:49151");
         }
         
         // 连接成功回调
         this.webSocket.onopen = function(evt) { 
            that.reconnectNumber = 0;
            that.webSocketStatus = "连接成功";
            that.webSocket.send(JSON.stringify({
               token:'123456'
            }));
            that.webSocket.send(JSON.stringify({
               operationType:'log'
            }));
         };
         
         // 收到服务器数据回调
         this.webSocket.onmessage = function(evt) {
            try {
               let data = JSON.parse(evt.data);
               that.collectionList = data.collectionList.sort().reverse();
               that.dataList = data.dataList;

               if(that.collectionActive == "" && that.collectionList.length > 0) {
                  that.collectionActive = that.collectionList[0]
               }

               // 当前选中集合为当天时，则实时增量更新最新日志
               // if(that.collectionActive == that.collectionList[0]){
                  
               //    that.webSocket.send(JSON.stringify({
               //       operationType:'log',
               //       collectionName:that.collectionActive,
               //       dataType:that.dataTypeActive,
               //       log_id:that.dataList[that.dataList.length - 1]._id
               //    }));
               // }
            } catch(e) {
               console.log(evt.data);
            }            
         };
         
         // 连接关闭后回调
         this.webSocket.onclose = function(evt) {
            that.webSocketStatus = "连接关闭";
         };    
         // 连接出错后回调
         this.webSocket.onerror = function(evt) {
            that.webSocket.close();
            that.webSocketStatus = "连接失败";
            that.webSocketReconnect(); // 连接失败自动重连
         };    
      },
      
      // 2.1 webSocket重新连接 -------------------------------------------------------------
      webSocketReconnect(){
         ++this.reconnectNumber;
         this.webSocket = null;
         this.webSocketConnect();
      }
   },
   watch:{
      dataList:{
         deep:true,
         handler() {
            let that = this;
            that.$nextTick(() => {
               that.$refs.logContent.scrollTop = that.$refs.logContent.scrollHeight;
            })
         }
      }
   },
   // Vue生命周期：页面离开之前被调用
   beforeDestroy: function () {
      this.webSocket.close();
      this.webSocket = null;
   }
   
}
</script>

<style scoped>

.container{
   display:flex;
   flex-direction:row;
}
.container>.dateList{
   display:flex;
   width: 160px;
   min-width: 160px;
   flex-direction: column;
   padding:10px 0px;
   border-right: 1px solid rgb(205, 205, 205);
}
.container>.dateList>.item{
   padding:8px 10px;
   cursor: pointer;
}
.container>.dateList>.item:hover{
   background:rgb(244, 244, 244);
}
.container>.dateList>.item.active{
   background:rgb(230, 230, 230);
   font-weight: bold;
}
.container>.content{
   display:flex;
   flex-direction:column;
   flex-grow: 1;
}
.container>.content>.tabbar{
   display:flex;
   border-bottom: 1px solid rgb(215, 215, 215);
}
.container>.content>.tabbar>.item{
   padding:6px 20px;
   cursor: pointer;
}
.container>.content>.tabbar>.item:hover{
   font-weight: bold;
}
.container>.content>.tabbar>.item.active{
   font-weight: bold;
}
.container>.content>.logContent{
   overflow: auto;
}
.container>.content>.logContent>.item{
   display:flex;
}
.container>.content>.logContent>.item.error{
   color:red;
}
.container>.content>.logContent>.item.warning{
   color:#ff8300;
}
.container>.content>.logContent>.item>*{
   padding:4px 4px;
}


</style>